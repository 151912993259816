import { Container, Stack } from '@chakra-ui/react';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import GradientText from '../components/GradientText/GradientText';
import { Page } from '../components/Page';

export default function Pricing() {
  const intl = useIntl();

  return (
    <Page>
      <Container pt={[2, 8]}>
        <Stack spacing={8} shouldWrapChildren>
          <GradientText>{intl.formatMessage({ id: 'pages.pricing.title' })}</GradientText>
        </Stack>
      </Container>
    </Page>
  );
}
